<template>
  <div class="trade-container">
    <Top></Top>
    <Fix></Fix>
    <div class="trade-box">
        <div class="service">
          <div class="service-pic">
            <div class="service-pic-title">
                <div>AI商标成功率评估</div>
                <div>携手知识产权行业专家和AI博士</div>
                <div>倾力打造国内首个AI商标成功率评估系统</div>
                <div>将商标申请的成功率判定精准到商标小类</div>
                <div>以AI系统判定代替传统人工判定</div>
                </div>
          </div>
          <div class="service-reg" v-if="v.goodsName == '商标注册'" :key="k" v-for="(v,k) in good">
            <div v-for="(item,index) in v.goodsInfoDTOList" class="service-list" >
              <div>{{item.goodsName}}<br>
              <!-- <span v-if="item.goodsName == 'AI商标注册'">人工智能  精确判断商标注册成功率</span> 
              <span v-else>解决商标注册过程中所有问题   全面规划，省心省力</span> -->
            </div>
              <div :title="item.goodsDescribe">{{item.goodsDescribe}}</div>
              <div>{{item.price}}<span>元/件</span></div>
              <div @click="totradedetail(v.goodsName,item.goodsName)">立即办理</div>
            </div>
          </div>
        </div>
        <div class="bread-box">
            <div class="bread-box-div2" v-for="(v,k) in good" v-if="v.goodsName == '商标信息'" :key="k">
                <div class="reg-div3">
                    <img :src="require('/static/images/regimg2.png')" alt="">
                    <span>{{v.goodsName}}</span>
                </div>
                <div class="reg-div4">
                    <div v-for="(item,index) in v.goodsInfoDTOList" v-if="index<6">
                      <div>
                        <img :src="require('/static/images/traderisk1.png')" v-if="item.goodsName == '商标变更'">
                        <img :src="require('/static/images/traderisk2.png')" alt="" v-if="item.goodsName == '商标续展'">
                        <img :src="require('/static/images/traderisk5.png')" alt="" v-if="item.goodsName == '商标宽展'">
                        <img :src="require('/static/images/traderisk3.png')" alt="" v-if="item.goodsName == '商标转让'">
                        <img :src="require('/static/images/traderisk4.png')" alt="" v-if="item.goodsName == '商标许可备案'">
                        <img :src="require('/static/images/traderisk6.png')" alt="" v-if="item.goodsName == '补发商标证书'">
                        <p>{{item.goodsName}}</p>
                        <p :title="item.goodsDescribe">{{item.goodsDescribe}}</p>
                        <div class="trade-bor"></div>
                        <p>￥<span>{{item.price}}</span><span>/件</span></p>
                        <div @click="totradedetail(v.goodsName,item.goodsName)">立即办理</div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="bread-box-div3">
                <div v-for="(v,k) in good" v-if="v.goodsName == '异议答辩'">
                  <div class="reg-div5">
                    <img :src="require('/static/images/regimg3.png')" alt="">
                    <span>{{v.goodsName}}</span>
                  </div>
                  <div class="reg-div6">
                      <div v-for="(item,index) in v.goodsInfoDTOList">
                        <div>
                          <img :src="require('/static/images/traderisk7.png')" alt="" v-if="index == 0">
                          <img :src="require('/static/images/traderisk8.png')" alt="" v-if="index == 1">
                          <p>{{item.goodsName}}</p>
                          <p :title="item.goodsDescribe">{{item.goodsDescribe}}</p>
                          <div class="trade-bor"></div>
                          <p>￥<span>{{item.price}}</span><span>/件</span></p>
                          <div @click="totradedetail(v.goodsName,item.goodsName)">立即办理</div>
                        </div>
                      </div>
                  </div>
                </div>
                <div v-for="(v,k) in good" v-if="v.goodsName == '撤三申请答辩'">
                    <div class="reg-div5">
                        <img :src="require('/static/images/regimg4.png')" alt="">
                        <span>{{v.goodsName}}</span>
                    </div>
                    <div class="reg-div6">
                      <div v-for="(item,index) in v.goodsInfoDTOList">
                        <div>
                          <img :src="require('/static/images/traderisk9.png')" alt="" v-if="index == 0">
                          <img :src="require('/static/images/traderisk8.png')" alt="" v-if="index == 1">
                          <p>{{item.goodsName}}</p>
                          <p :title="item.goodsDescribe">{{item.goodsDescribe}}</p>
                          <div class="trade-bor"></div>
                          <p>￥<span>{{item.price}}</span><span>/件</span></p>
                          <div @click="totradedetail(v.goodsName,item.goodsName)">立即办理</div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
            <div class="bread-box-div4" v-for="(v,k) in good" v-if="v.goodsName == '驳回复审'">
                <div class="reg-div5">
                    <img :src="require('/static/images/regimg5.png')" alt="">
                    <span>{{v.goodsName}}</span>
                  </div>
                  <div class="reg-div4">
                    <div v-for="(item,index) in v.goodsInfoDTOList">
                      <div>
                        <img :src="require('/static/images/traderisk10.png')" alt="" v-if="index == 0">
                        <p>{{item.goodsName}}</p>
                        <p :title="item.goodsDescribe">{{item.goodsDescribe}}</p>
                        <div class="trade-bor"></div>
                        <p>￥<span>{{item.price}}</span><span>/件</span></p>
                        <div @click="totradedetail(v.goodsName,item.goodsName)">立即办理</div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name: 'trade',
  data(){
    return{
      good:[],
      id:''
    }
  },
  components: {
    Top,
    Foot,
    Fix
  },
  methods:{
    totradedetail(val1,val2){
      this.$router.push({
          path:'/tradeDetail',
          query:{
            parentName:val1,
            secondName:val2,
            }
          });
    },
    getGood(){
      
      this.$http.post(this.GLOBAL.new_url+'/goods/service/goods/queryGoodsList',{
        goodsLevel:'0',
        userCode:this.$cookie.getCookie('u_id'),
        goodsType:'1'
      }).then(res=>{
                    // console.log(res)
          if(res.data.code == "200"){
            const agent = window.localStorage.getItem('UserAgent')
              this.good = res.data.data;
              for(var i =0;i<this.good.length;i++){
                for(var j =0;j<this.good[i].goodsInfoDTOList.length;j++){
                  var _this=this;
                  if(agent == 'HT'){
                    _this.$set(this.good[i].goodsInfoDTOList[j], 'price', this.good[i].goodsInfoDTOList[j].proxyPrice+100);
                  }else{
                    _this.$set(this.good[i].goodsInfoDTOList[j], 'price', this.good[i].goodsInfoDTOList[j].proxyPrice);
                  }
                  
                }
              }
          }
          // console.log(this.good)
      })
    }
  },
  mounted(){
    this.getGood();
  }
}

</script>

<style scoped>
  .trade-box{
    background-color: #fafafa;
  }
  .service{
    width: 100%;
    position: relative;
    height: 610px;
    margin-bottom: 80px;
  }
  .service-pic{
    width: 100%;
    height: 400px;
    background: url("../../../static/images/bitmap.png")  no-repeat;
    background-size: cover;
    padding-top: 80px;
  }
  .service-pic-title{
    width: 1200px;
    margin: 0 auto;
    color: #FEF8EE;
    font-size: 16px;
  }
  .service-pic-title>div{
     margin-bottom: 12px;
  }
  .service-pic-title>div:nth-of-type(1){
    color: #FFFFFF;
    font-size: 36px;
    margin-bottom: 24px;
  }
  .service-reg{ 
    position: absolute;
    top:322px;
    width: 1200px;
    height: 238px;
    left: 50%;
    transform: translate(-50%,0);
  }
  .service-reg>div{
    float: left;
    width: 506px;
    height: 209px;
    background: linear-gradient(360deg, #FFFFFF 0%, #FFEFDE 100%);
    border: 1px solid #FFE0BD;
    padding: 30px 0 0 92px;
  }
  .service-reg>div:nth-of-type(1){
    border-right:none;
  }
  .service-list>div:nth-of-type(1){
    margin-bottom: 10px;
    font-size: 18px;
    color: #000000;
    font-weight: 550;
    line-height: 25px;
  }
  .service-list>div:nth-of-type(2){
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 400;
    color: #706C68;
    width: 450px;
    height: 40px;
  }
  .service-list>div:nth-of-type(3){
    margin-bottom: 14px;
    font-size: 30px;
    font-weight: bold;
    color: #F96009;
  }
  .service-list>div:nth-of-type(3)>span{
    font-size: 12px;
    font-weight: 400;
    margin-left: 3px;
  }
  .service-list>div:nth-of-type(4){
    width: 104px;
    height: 34px;
    background: #F96009;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .bread-box{
    width: 1200px;
    margin: 0 auto;
  }
  .bread-box-div2{
    width: 1200px;
    height:765px;
    padding-top: 60px;
    background-color: #ffffff;
    margin-bottom: 20px;
  }
  .reg-div3{
    width: 256px;
    height: 50px;
    margin: 0 auto 36px;
  }
  .reg-div3>img{
    width: 32px;
    height: 32px;
    vertical-align: middle;
  }
  .reg-div3>span{
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    vertical-align: middle;
  }
  /* .reg-div4{
    height: 220px;
  } */
  .reg-div4>div{
    float: left;
    width: 280px;
    height: 297px;
    background: url('../../../static/images/tradebac.png') no-repeat;
    background-size: cover;
    margin:0 20px 20px 0;
    text-align: center;
  }
  .reg-div4>div:nth-of-type(1){
    margin-left: 10px;
  }
  .reg-div4>div:nth-of-type(4){
    margin-right: 0;
  }
  .reg-div4>div>div,.reg-div6>div>div{
    width: 216px;
    margin: 16px auto 0;
  }
  .reg-div4>div:nth-of-type(5){
    margin-left: 10px;
  }
  .reg-div4>div>div>p:nth-of-type(1),.reg-div6>div>div>p:nth-of-type(1){
    margin: 8px 0 9px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .reg-div4>div>div>p:nth-of-type(2),.reg-div6>div>div>p:nth-of-type(2){
    font-size: 14px;
    font-weight: 100;
    width: 168px;
    height: 40px;
    margin:0 auto 16px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .reg-div4>div>div>p:nth-of-type(3),.reg-div6>div>div>p:nth-of-type(3){
    color: #EB5E00;
    margin-bottom: 12px;
  }
  .reg-div4>div>div>p:nth-of-type(3)>span:nth-of-type(1),.reg-div6>div>div>p:nth-of-type(3)>span:nth-of-type(1){
    font-size: 24px;
  }
  .reg-div4>div>div>p:nth-of-type(3)>span:nth-of-type(2),.reg-div6>div>div>p:nth-of-type(3)>span:nth-of-type(2){
    color: #333333;
  }
  .reg-div4>div>div>div:nth-of-type(2),.reg-div6>div>div>div:nth-of-type(2){
    width: 96px;
    height: 32px;
    border-radius: 4px;
    background-color: #EB5E00;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    color: white;
    font-size: 14px;
    margin: 0 auto;
  }
  .trade-bor{
    width: 216px;
    border-top:1px solid #f7ede2;
    margin-bottom: 12px;
  }
  .bread-box-div3{
    height: 507px;
    width: 1200px;
    margin-bottom: 20px;
  }
  .bread-box-div3>div{
    float: left;
    width: 590px;
    height: 447px;
    padding-top:60px;
    background-color: #ffffff;
  }
  .bread-box-div3>div:nth-of-type(1){
    margin-right: 20px;
  }
  .reg-div5{
    width: 400px;
    height: 50px;
    text-align: center;
    margin: 0 auto 36px;
  }
  .reg-div5>img{
    width: 32px;
    height: 32px;
    vertical-align: middle;
  }
  .reg-div5>span{
    font-size: 36px;
    font-weight: 500;
    color: #333333;
    vertical-align: middle;
  }
   .reg-div6{
    height: 297px;
  }
  .reg-div6>div{
    float: left;
    width: 280px;
    height: 297px;
    background: url('../../../static/images/tradebac.png') no-repeat;
    background-size: cover;
    margin:0 10px;
    text-align: center;
  }
  .reg-div6>div:nth-of-type(1){
    margin-right: 0;
  }
  .reg-div6>div>p:nth-of-type(1){
    font-size: 16px;
    font-weight: 500;
    margin: 24px 0 14px;
    color: #333333;
  }
  .reg-div6>div>p:nth-of-type(2){
    font-size: 14px;
    color: #EB5E00;
  }
  .bread-box-div4{
    width: 1200px;
    height: 447px;
    padding-top: 60px;
    background-color: #ffffff;
  }
</style>
